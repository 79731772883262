<script lang="ts">
    import Frame from '$components/basic/utils/Frame.svelte';
    import { Button } from '$components/basic';
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();

    export let title = '';
    export let msg = '';
    export let useHtmlMsg = true;
    export let color: 'dark' | 'gray' | 'green' | 'red' = 'dark';
    export let textAlignment: 'left' | 'center' | 'right' = 'center';
    let textAlignmentClass = 'text-center';
    $: switch (textAlignment) {
        case 'left':
            textAlignmentClass = 'text-left';
            break;
        case 'center':
            textAlignmentClass = 'text-center';
            break;
        case 'right':
            textAlignmentClass = 'text-right';
            break;
    }
    export let onConfirm: (v: any) => void = () => true;
    export let confirmText = '확인';

    function handleConfirm() {
        dispatch('confirm', true);
        onConfirm?.(true);
    }
</script>

<div class="flex max-h-full w-full flex-col text-center">
    <Frame class="flex items-center justify-between rounded-t-lg border-b p-6">
        <h2 class="whitespace-pre p-0 text-lg font-semibold text-gray-900 dark:text-white">
            {title || ' '}
        </h2>
    </Frame>
    <div class="flex flex-col gap-2 overflow-y-auto overscroll-contain p-6" style="max-height: calc(100% - 6.0625rem - 4.75rem)" role="document" on:wheel|stopPropagation|passive>
        <h3 class="whitespace-pre-wrap text-base font-normal text-gray-700 dark:text-gray-400 {textAlignmentClass}">
            {#if useHtmlMsg}
                {@html msg}
            {:else}
                {msg}
            {/if}
        </h3>
    </div>
    <Frame class="flex items-center justify-end gap-2 rounded-b-lg border-t border-gray-200 p-6">
        <Button {color} class="flex" on:click={handleConfirm}>
            <slot name="confirm">
                {@html confirmText}
            </slot>
        </Button>
    </Frame>
</div>

<!-- 
@component
## Features
    - alert modal
    - title
    - msg
    - textAlignment
    - color
    - onConfirm

## Props
    @prop title: string = '';
    @prop msg: string = '';
    @prop useHtmlMsg: boolean = true;
    @prop color: 'dark' | 'gray' | 'green' | 'red' = 'dark';
    @prop textAlignment: 'left' | 'center' | 'right' = 'center';
    @prop onConfirm: () => void = () => {};
    @prop confirmText: string = '확인';

## Example
    ```
        <Button on:click={() => {defaultModal = true; returnValue = false;}}>Modal</Button>
        <Modal bind:open={defaultModal} size="lg" {...args}>
            <Info
                title="info" 
                msg={longMsg}
                on:confirm={e => {returnValue = e.detail;}}
            />
        </Modal>
        ---
        <Button on:click={async() => {
            returnValue = await info({ title: 'Info', msg: longMsg})
        }}>
            Info modal
        </Button>
    ```
-->
